<template>
    <div class="wrapper">
        <table class="custom-table">
            <thead>
                <tr>
                    <td rowspan="2" class="custom-th custom-th-bg" style="width:.6rem;">
                        序号
                    </td>
                    <td rowspan="2" class="custom-th custom-th-bg" style="width:1rem;">
                        分类
                    </td>
                    <td colspan="4" class="custom-th custom-th-bg">
                        销量(万方)
                    </td>
                    <td rowspan="2" class="custom-th custom-th-bg">
                        价格(元/方)
                    </td>
                    <td rowspan="2" class="custom-th custom-th-bg">
                        备注
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg">
                        本月
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.2rem;">
                        本年累计
                    </td>
                    <td class="custom-th custom-th-bg">
                        本年计划
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.2rem;">
                        年度计划完成率
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in data" :key="index" :class="{'border6':index<data.length-1}">
                    <td>{{ row.sort_num }}</td>
                    <td>{{ row.type_name }}</td>
                    <td v-if="index<(data.length-1)">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="row.sales"
                            class="edit-input"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            @blur="computData('sales', row, row.sales)"
                            :class="{'red': parseFloat(row.sales)<0}"
                        ></el-input>
                    </td>
                    <td v-if="index===(data.length-1)" :class="{'red': parseFloat(row.sales)<0}">
                        {{ row.sales }}
                    </td>
                    <td :class="{'red': parseFloat(row.sales_year)<0}">
                        {{ row.sales_year }}
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="row.plan_year"
                            class="edit-input"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            @blur="computData('plan_year', row, row.plan_year)"
                            :class="{'red': parseFloat(row.plan_year)<0}"
                        ></el-input>
                    </td>
                    <td :class="{'red': parseFloat(row.finish_rate)<0}">
                        {{ row.finish_rate }}
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="row.price"
                            class="edit-input"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(row.price)<0}"
                        ></el-input>
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="row.remarks"
                            class="edit-input"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(row.remarks)<0}"
                        ></el-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: [],
            oldData: [],
            concretesSales: null,
            preMonRes: null,
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取上月产品流向
        getPreMonFlow(instanceRes) {
            return this.$axios.get('/interfaceApi/report/flowto/' + instanceRes.report_id);
        },
        // 获取-产品流向
        getFlow() {
            this.$axios
                .get('/interfaceApi/report/flowto/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;

                        if (this.type !== 'approval') {
                            if (this.typeList[1].state === 0) {
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    // eslint-disable-next-line max-depth
                                    if (instanceRes) {
                                    // eslint-disable-next-line max-depth
                                        if (instanceRes.report_state === 3) {
                                            const preMonRes = await this.getPreMonFlow(instanceRes);
                                            // eslint-disable-next-line max-depth
                                            if (preMonRes) {
                                                res.map((item, index) => {
                                                    item.plan_year = preMonRes[index].plan_year;
                                                    if (index < res.length - 1) {
                                                        item.sales_year = preMonRes[index].sales_year;
                                                    }
                                                });
                                            }
                                        } else {
                                            this.$message.warning('上月【产品流向】未填报审核通过，请先填写，以免影响本年累计计算！');
                                        }
                                    }
                                }
                            }
                            if (this.typeList[0].state === 0) {
                                this.$message.warning('本月【产销情况】未填，请先填写，以免影响【产品流向】其他类别计算！');
                            }
                            if (this.reportMonth.report_month > 1) {
                                const instanceRes = await this.getInstance();
                                if (instanceRes) {
                                    // eslint-disable-next-line max-depth
                                    if (instanceRes.report_state === 3) {
                                        const preMonRes = await this.getPreMonFlow(instanceRes);
                                        // eslint-disable-next-line max-depth
                                        if (preMonRes) {
                                            this.preMonRes = preMonRes;
                                            res.map((item, index) => {
                                                item.sales_year_pre = this.preMonRes[index].sales_year;
                                            });
                                            this.oldData = JSON.parse(JSON.stringify(res));
                                        }

                                    }
                                }
                            }
                            this.oldData = JSON.parse(JSON.stringify(res));
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-产品流向
        save() {
            let newData = [];
            const editData = this.data;
            this.oldData.map((item, index) => {
                const boo = util.Compare(item, editData[index]);
                if (!boo) {
                    newData.push(editData[index]);
                }
            });
            if (newData.length < 1) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            let newDataFalse = false;
            newData = newData.map(item => {
                for (const key in item) {
                    let notParse = false;
                    if (key === 'rfd_id' || key === 'report_id' || key === 'type_name' || key === 'type_code'
                    || key === 'remarks' || key === 'sort_num' || key === 'sales_year' || key === 'finish_rate') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!item[key]) {
                            item[key] = 0;
                        } else {
                            item[key] = parseFloat(item[key]);
                        }
                        if (item[key] < 0) {
                            newDataFalse = true;
                        }
                    }
                }
                return item;
            });
            if (!newDataFalse) {
                this.$axios
                    .post('/interfaceApi/report/flowto/save', newData)
                    .then(async res => {
                        if (res.length > 0) {
                            res.map(item => {
                                this.data = this.data.map((v, index) => {
                                    if (v.type_code === item.type_code) {
                                        v = item;
                                        if (this.preMonRes) {
                                            v.sales_year_pre = this.preMonRes[index].sales_year;
                                        }
                                    }
                                    return v;
                                });
                            });
                            this.oldData = JSON.parse(JSON.stringify(this.data));
                        }
                        this.$message.success('保存成功');
                        this.$emit('save-end', 'flowto', 'success');
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                        this.$emit('save-end', 'sales', 'error');
                    });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }

        },
        // 本年累计销量
        getTotalSalesYear(name) {
            return this.$axios.get('/interfaceApi/report/flowto/totalsalesyear/' + this.reportMonth.report_id + '/' + name);
        },
        // 获取混凝土销量
        getConcretesSales() {
            return this.$axios.get('/interfaceApi/report/sales/' + this.reportMonth.report_id);
        },
        // 计算
        // eslint-disable-next-line complexity
        async computData(type, rowData, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            // 本年累计销量
            if (type === 'sales') {
                if (!rowData.sales) {
                    rowData.sales = 0;
                }
                if (!rowData.sales_year_pre) {
                    rowData.sales_year_pre = 0;
                }
                rowData.sales_year = util.toFixed6(parseFloat(rowData.sales_year_pre) + parseFloat(rowData.sales));
                this.data.map(item => {
                    if (rowData.type_code === item.type_code) {
                        item = rowData;
                        if (parseFloat(item.sales_year) && parseFloat(item.plan_year) ) {
                            item.finish_rate = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                        } else {
                            item.finish_rate = 0;
                        }
                    }
                    return item;
                });
                // 其他 = 混凝土本月销量 - 流向所有分类的和
                if (this.concretesSales) {
                    let sumFlowYearSales = 0;
                    this.data.map((item, index) => {
                        if (index < this.data.length - 1) {
                            if (parseFloat(item.sales_year)) {
                                sumFlowYearSales = util.toFixed6(parseFloat(sumFlowYearSales) + parseFloat(item.sales_year));
                            }
                        }
                    });
                    this.data[this.data.length - 1].sales_year = util.toFixed6(this.concretesSales.total.concrete_sales_year - sumFlowYearSales);
                } else {
                    const concretesSales = await this.getConcretesSales();
                    if (concretesSales) {
                        this.concretesSales = concretesSales;
                        let sumFlowYearSales = 0;
                        this.data.map((item, index) => {
                            if (index < this.data.length - 1) {
                                if (parseFloat(item.sales_year)) {
                                    sumFlowYearSales = util.toFixed6(parseFloat(sumFlowYearSales) + parseFloat(item.sales_year));
                                }
                            }
                        });
                        this.data[this.data.length - 1].sales_year = util.toFixed6(this.concretesSales.total.concrete_sales_year
                            - sumFlowYearSales);
                    }
                }

                // 其他 = 混凝土本月销量 - 流向所有分类的和
                if (this.concretesSales) {
                    let sumFlowYearSales = 0;
                    this.data.map((item, index) => {
                        if (index < this.data.length - 1) {
                            if (item.sales_year) {
                                sumFlowYearSales = util.toFixed6(parseFloat(sumFlowYearSales) + parseFloat(item.sales_year));
                            }
                        }
                    });
                    if (!this.concretesSales.total.concrete_sales_year) {
                        this.concretesSales.total.concrete_sales_year = 0;
                    }
                    if (!this.concretesSales.total.mortar_sales_year) {
                        this.concretesSales.total.mortar_sales_year = 0;
                    }
                    if (!sumFlowYearSales) {
                        sumFlowYearSales = 0;
                    }
                    if (this.concretesSales.total.concrete_sales_year && this.concretesSales.total.mortar_sales_year && sumFlowYearSales) {
                        this.data[this.data.length - 1].sales_year = util.toFixed6(parseFloat(this.concretesSales.total.concrete_sales_year)
                            + parseFloat(this.concretesSales.total.mortar_sales_year) - parseFloat(sumFlowYearSales));
                    }

                } else {
                    const concretesSales = await this.getConcretesSales();
                    if (concretesSales) {
                        this.concretesSales = concretesSales;
                        let sumFlowYearSales = 0;
                        this.data.map((item, index) => {
                            if (index < this.data.length - 1) {
                                if (item.sales_year) {
                                    sumFlowYearSales = util.toFixed6(parseFloat(sumFlowYearSales) + parseFloat(item.sales_year));
                                }
                            }
                        });
                        // eslint-disable-next-line max-depth
                        if (!this.concretesSales.total.concrete_sales_year) {
                            this.concretesSales.total.concrete_sales_year = 0;
                        }
                        // eslint-disable-next-line max-depth
                        if (!this.concretesSales.total.mortar_sales_year) {
                            this.concretesSales.total.mortar_sales_year = 0;
                        }
                        // eslint-disable-next-line max-depth
                        if (!sumFlowYearSales) {
                            sumFlowYearSales = 0;
                        }
                        // eslint-disable-next-line max-depth
                        if (this.concretesSales.total.concrete_sales_year && this.concretesSales.total.mortar_sales_year && sumFlowYearSales) {
                            this.data[this.data.length - 1].sales_year = util.toFixed6(parseFloat(this.concretesSales.total.concrete_sales_year)
                            + parseFloat(this.concretesSales.total.mortar_sales_year) - parseFloat(sumFlowYearSales));
                        }
                    }
                }
                // 其他 = 混凝土本月销量 - 流向所有分类的和
                if (this.concretesSales) {
                    let sumFlowSales = 0;
                    this.data.map((item, index) => {
                        if (index < this.data.length - 1) {
                            if (item.sales) {
                                sumFlowSales = util.toFixed6(parseFloat(sumFlowSales) + parseFloat(item.sales));
                            }
                        }
                    });
                    if (!this.concretesSales.total.concrete_sales) {
                        this.concretesSales.total.concrete_sales = 0;
                    }
                    if (!this.concretesSales.total.mortar_sales) {
                        this.concretesSales.total.mortar_sales = 0;
                    }
                    if (!sumFlowSales) {
                        sumFlowSales = 0;
                    }
                    this.data[this.data.length - 1].sales = util.toFixed6(parseFloat(this.concretesSales.total.concrete_sales)
                        + parseFloat(this.concretesSales.total.mortar_sales) - parseFloat(sumFlowSales));
                } else {
                    const concretesSales = await this.getConcretesSales();
                    if (concretesSales) {
                        this.concretesSales = concretesSales;
                        let sumFlowSales = 0;
                        this.data.map((item, index) => {
                            if (index < this.data.length - 1) {
                                if (item.sales) {
                                    sumFlowSales = util.toFixed6(parseFloat(sumFlowSales) + parseFloat(item.sales));
                                }
                            }
                        });
                        if (!this.concretesSales.total.concrete_sales) {
                            this.concretesSales.total.concrete_sales = 0;
                        }
                        if (!this.concretesSales.total.mortar_sales) {
                            this.concretesSales.total.mortar_sales = 0;
                        }
                        if (!sumFlowSales) {
                            sumFlowSales = 0;
                        }
                        this.data[this.data.length - 1].sales = util.toFixed6(parseFloat(this.concretesSales.total.concrete_sales)
                        + parseFloat(this.concretesSales.total.mortar_sales) - parseFloat(sumFlowSales));
                    }
                }
            }
            if (type === 'plan_year') {
                this.data.map(item => {
                    if (rowData.type_code === item.type_code) {
                        item = rowData;
                        if (parseFloat(item.sales_year) && parseFloat(item.plan_year)) {
                            item.finish_rate = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                        } else {
                            item.finish_rate = 0;
                        }
                    }
                    return item;
                });
            }
        },
    },
    created() {
        this.getFlow();
    },
    mounted() {},
};
</script>
<style>
.el-table .cell{
    color: #022782;
    font-size: .14rem;
}
.el-table .el-input__inner{
    line-height: .32rem;
    height: .32rem;
    width: calc(100% - .2rem);
    margin:0.05rem .1rem;
}
</style>